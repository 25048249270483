import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_AUTHED } from "store/actions";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "routes";

//Cookcies
import Cookies from "js-cookie";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

//Api
import api from "config/api";

//Global style
import "./assets/scss/global.scss";
import { useNavigate } from "react-router";

import useGetRole from "./hooks/useGetRole";

// ==============================|| APP ||============================== //
const App = () => {
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Auth state and token handling
  const [Authed, setAuthed] = useState(true);

  //Get Role
  const { role } = useGetRole();

  // FIND IF USER IS LOGGED IN OR NOT
  const checkIfLoggedIn = async () => {
    try {
      await api.getDetail();
      setAuthed(true);
    } catch (error) {
      setAuthed(false);
    }
  };
  useEffect(() => {
    // checkIfLoggedIn();
  }, [Authed]);

  useEffect(() => {
    setAuthed(customization["Authed"]);
  }, [customization["Authed"]]);
  useEffect(() => {
    dispatch({ type: SET_AUTHED, Authed });
  }, [dispatch, Authed]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes role={role} />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
