import axios from "axios";
import Cookies from "js-cookie";
// axios.defaults.baseURL = '1asdasdsa';
const config = {
  udata: Cookies.get("admintoken"),
  //Server BASEURL
  // server run
  baseURL: "https://api.ileading360.com/v1/",
  //locale
  // baseURL: "http://192.168.200.104:5000/v1/",
  axiosHandler: () => {
    return axios.create({
      baseURL: config.baseURL,
      headers: {
        authorization: "Bearer" + " " + config.udata,
      },
    });
  },
};

export default config;
