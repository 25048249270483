import { useEffect, useState } from "react";
import api from "config/api";
import Cookies from "js-cookie";

const useGetRole = (): { role: string } => {
  const [role, setRole] = useState<string>("");
  const accessToken = Cookies.get("admintoken");

  useEffect(() => {
    accessToken &&
      api.getRole().then((res) => setRole(res.data.admin.admin_role));
  }, [accessToken]);

  return { role };
};
export default useGetRole;
