import dashboard from "./dashboard";
import customers from "./customers";
import data from "./costomers_data";
import feedback from "./feedback";
import voucherAndPartnership from "./vocher&partnership";
// import myAccount from './myAccount';
import development from './development';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard,
    voucherAndPartnership,
    feedback,
    customers,
    data,
    development,
  ],
};

export default menuItems;
