// assets
import { IconLayout } from "@tabler/icons";

import QuizIcon from "@mui/icons-material/Quiz";
// constant
const icons = { IconLayout, QuizIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const feedback = {
  id: "feedback",
  title: "Feedback",
  type: "group",
  caption: "",
  children: [
    {
      id: "questions-item",
      title: "Questions",
      type: "item",
      url: "/questions",
      icon: icons.QuizIcon,
      breadcrumbs: true,
    },
  ],
};

export default feedback;
