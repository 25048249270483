import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import useRoleHandler from "../hooks/useGetRole";
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
  const customization = useSelector((state) => state.customization);
  const Authed = customization['Authed'];
  const token = Cookies.get('admintoken');

  if (token) {
    return children;
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export default PrivateRoute;
