import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses
} from '@mui/material';

import api from 'config/api';

const DONE_VALUE = 2;
const NUMBER_ASSESSMENTS = 10;
const PERCENTAGE = Math.round((DONE_VALUE / NUMBER_ASSESSMENTS) * 100);

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary[1100]
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.secondary[1000],
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative'
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mb: 2 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            Progress
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color={'inherit'}>{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const theme = useTheme();
    const borderRadius = useSelector((state) => state.customization.borderRadius);

    const [state, setState] = React.useState({
        all_package_sold: '',
        all_users: '',
        companies: '',
        single_users: '',
        sme_coach_users: ''
    });

    //Get Status of Leading
    // const getStateLeading = () => {
    //     api.leadingStatus()
    //         .then((res) => {
    //             setState(res.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    // React.useEffect(() => {
    //     getStateLeading();
    // }, []);
    return (
        <CardStyle sx={{ mt: 3 }}>
            <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                    Live Status
                                </Typography>
                            }
                            secondary={
                                <Grid style={{ background: '#ffe5d7', borderRadius: borderRadius }} mt={2} p={2}>
                                    <Grid mb={0.5} container justifyContent={'space-between'} alignItems="center">
                                        <Typography item variant="h6">
                                            Packages Sold
                                        </Typography>
                                        <Typography item variant="h6">
                                            {state?.all_package_sold}
                                        </Typography>
                                    </Grid>
                                    <Grid mb={0.5} container justifyContent={'space-between'} alignItems="center">
                                        <Typography item variant="h6">
                                            Total Users
                                        </Typography>
                                        <Typography item variant="h6">
                                            {state?.all_users}
                                        </Typography>
                                    </Grid>
                                    <Grid mb={0.5} container justifyContent={'space-between'} alignItems="center">
                                        <Typography item variant="h6">
                                            Companies
                                        </Typography>
                                        <Typography item variant="h6">
                                            {state?.companies}
                                        </Typography>
                                    </Grid>
                                    <Grid mb={0.5} container justifyContent={'space-between'} alignItems="center">
                                        <Typography item variant="h6">
                                            Individual Users
                                        </Typography>
                                        <Typography item variant="h6">
                                            {state?.single_users}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                </List>
            </CardContent>
        </CardStyle>
    );
};

export default MenuCard;
