// material-ui
import logo from 'assets/images/logo360.png';
import logoLight from 'assets/images/Logo360Light.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
    return props.light ? (
        <img src={logoLight} alt="Verv Logo Light" width={props.size ? props.size : '100'} style={props.style} />
    ) : (
        <img src={logo} alt="Verv Logo" width={props.size ? props.size : '100'} style={props.style} />
    );
};

export default Logo;
