import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import PrivateRoute from "./PrivateRoutes";
// import CustomersData from '../views/query/index';
// import Customers from '../views/Customers/index';
// import FreemiumData from '../views/freemium/index';
// import Voucher from '../views/voucher/index';

// dashboard routing
const Partners = Loadable(lazy(() => import("views/partners")));
const Vouchers = Loadable(lazy(() => import("views/voucher-codes")));
const Tasks = Loadable(lazy(() => import('views/tasks')));
const Questions = Loadable(lazy(() => import("views/questions")));
const Feedback = Loadable(lazy(() => import("views/feedback")));
const NewsLetter = Loadable(lazy(() => import("views/newsletter")));
const Ebooks = Loadable(lazy(() => import('views/ebooks')));
const Voucher = Loadable(lazy(() => import('views/voucher/index')));
const Customers = Loadable(lazy(() => import('views/Customers/index')));
const CustomersData = Loadable(lazy(() => import('views/query/index')));
const FreemiumData = Loadable(lazy(() => import('views/freemium/index')));
const Transactions = Loadable(lazy(() => import('views/transactions/index')));
const Products = Loadable(lazy(() => import('views/products/index')));
const SingleUser = Loadable(lazy(() => import('views/singleusers/index')));
const UsersReport = Loadable(lazy(() => import('views/users-report/index')));
const Companies = Loadable(lazy(() => import('views/companies/index')));
const Usercompany = Loadable(lazy(() => import('views/companies/users/index')));
const CustomerData = Loadable(lazy(() => import('views/CustomerData')));
const Contactus = Loadable(lazy(() => import('views/contactus')));
const Detail = Loadable(lazy(() => import('views/products/detail/index')));
const SummaryReport = Loadable(
  lazy(() => import('views/summary-report/index'))
);
const ThemeDetail = Loadable(
  lazy(() => import('views/products/detail/theme/index'))
);

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = (role) => {
  const routes = [
    {
      path: '/product',
      element: (
        <PrivateRoute>
          <Products />
        </PrivateRoute>
      ),
    },

    {
      path: 'products',
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          ),
        },
        {
          path: ':detail',
          element: (
            <PrivateRoute>
              <Detail />
            </PrivateRoute>
          ),
        },
      ],
    },

    {
      path: 'products/:detail/:theme',
      element: (
        <PrivateRoute>
          <ThemeDetail />
        </PrivateRoute>
      ),
    },
    {
      path: 'users',
      element: (
        <PrivateRoute>
          <SingleUser />
        </PrivateRoute>
      ),
    },
    {
      path: 'reports',
      element: (
        <PrivateRoute>
          <UsersReport />
        </PrivateRoute>
      ),
    },
    {
      path: 'summary-report',
      element: (
        <PrivateRoute>
          <SummaryReport />
        </PrivateRoute>
      ),
    },
    {
      path: 'partners',
      element: (
        <PrivateRoute>
          <Partners />
        </PrivateRoute>
      ),
    },
    {
      path: 'transactions',
      element: (
        <PrivateRoute>
          <Transactions />
        </PrivateRoute>
      ),
    },
    {
      path: 'voucher-code',
      element: (
        <PrivateRoute>
          <Vouchers />
        </PrivateRoute>
      ),
    },
    {
      path: 'it-tasks',
      element: (
        <PrivateRoute>
          <Tasks />
        </PrivateRoute>
      ),
    },

    // {
    //   path: 'companies',
    //   children: [
    //     {
    //       path: '',
    //       element: (
    //         <PrivateRoute>
    //           <Companies />
    //         </PrivateRoute>
    //       ),
    //     },
    //     {
    //       path: ':name',
    //       element: (
    //         <PrivateRoute>
    //           <Usercompany />
    //         </PrivateRoute>
    //       ),
    //     },
    //   ],
    // },

    {
      path: 'customers-data',
      element: (
        <PrivateRoute>
          <CustomerData />
        </PrivateRoute>
      ),
      children: [],
    },
    {
      path: 'contact-us',
      element: (
        <PrivateRoute>
          <Contactus />
        </PrivateRoute>
      ),
      children: [],
    },
    {
      path: 'voucher',
      element: (
        <PrivateRoute>
          <Voucher />
        </PrivateRoute>
      ),
    },
    {
      path: 'newsletter',
      element: (
        <PrivateRoute>
          <NewsLetter />
        </PrivateRoute>
      ),
    },
    {
      path: 'ebooks',
      element: (
        <PrivateRoute>
          <Ebooks />
        </PrivateRoute>
      ),
    },
    {
      path: 'feedback',
      element: (
        <PrivateRoute>
          <Feedback />
        </PrivateRoute>
      ),
    },
    {
      path: 'query',
      element: (
        <PrivateRoute>
          <CustomersData />
        </PrivateRoute>
      ),
    },
    {
      path: 'freemium',
      element: (
        <PrivateRoute>
          <FreemiumData />
        </PrivateRoute>
      ),
    },
    {
      path: 'customers',
      element: (
        <PrivateRoute>
          {' '}
          <Customers />
        </PrivateRoute>
      ),
    },
    {
      path: 'questions',
      element: (
        <PrivateRoute>
          <Questions />
        </PrivateRoute>
      ),
    },
  ];

  const pathsToExcludeForNonSuperAdmin = [
    'customers',
    '/product',
    'products',
    'products/:detail/:theme',
  ];
  const noneSuperAdminRoutes = routes.filter(
    (route) => !pathsToExcludeForNonSuperAdmin.includes(route.path)
  );

  const roleBasedRoutes = role === 'sUpErADmin' ? routes : noneSuperAdminRoutes;

  return {
    path: '/',
    element: <MainLayout />,
    children: roleBasedRoutes,
  };
};

export default MainRoutes;
