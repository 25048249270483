// assets
import { IconLayout } from '@tabler/icons';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DiscountIcon from '@mui/icons-material/Discount';
// constant
const icons = { IconLayout, ManageAccountsIcon, DiscountIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const development = {
  id: 'development',
  title: 'Development',
  type: 'group',
  caption: '',
  children: [
    {
      id: 'tasks',
      title: 'IT Tasks',
      type: 'item',
      url: '/it-tasks',
      icon: icons.ManageAccountsIcon,
      breadcrumbs: true,
    },
  ],
};

export default development;
