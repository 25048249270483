import React, { useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import MenuCard from "./MenuCard";
import { drawerWidth } from "store/constant";

import { useDispatch, useSelector } from "react-redux";

//API
import api from "config/api";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const customization = useSelector((state) => state.customization);

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection light={!matchUpMd} />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 62px)" : "calc(100vh - 94px)",
            paddingLeft: "16px",
            paddingRight: "16px",
            borderRadius: `${customization.borderRadius}px`,
            marginTop: 0,
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 6,
            backgroundColor: theme.palette.background.menuColor,
          }}
        >
          <MenuList />
          <MenuCard />
          <Grid flex flexDirection={"row"}>
            <Typography
              sx={{
                color: "white",
                paddingBottom: 2,
                fontSize: "12px",
              }}
            >
              {"Designed and Developed by "}
            </Typography>
            <Link href={"http://thecyberiatech.com"} target="blank">
              <Typography
                sx={{
                  color: "white",
                  paddingBottom: 2,
                  fontSize: "12px",
                  fontWeight: "600",
                  display: "inline-block",
                  cursor: "pointer",
                }}
              >
                {"The Cyberia Tech"}
              </Typography>
            </Link>
          </Grid>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          <MenuCard />
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? (drawerOpen ? drawerWidth : '0') : '0',
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: `${customization.borderRadius}px`,

            height: matchUpMd ? `calc(100vh - 102px)` : `calc(100vh - 32px)`,
            my: matchUpMd ? 0 : 2,
            mx: 2,

            width: drawerWidth,
            background: theme.palette.background.menuColor,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
            },
            [theme.breakpoints.down('md')]: {
              visibility: !drawerOpen ? 'hidden' : 'visible',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
