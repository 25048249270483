// assets
import { IconCheckbox, IconReportAnalytics } from "@tabler/icons";

//MUI Icon
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChatIcon from "@mui/icons-material/Chat";
import RequestQuote from '@mui/icons-material/RequestQuote';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ContactsIcon from '@mui/icons-material/Contacts';
import { Percent, Feed } from '@mui/icons-material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PlayLesson from '@mui/icons-material/PlayLesson';
import Transitions from './../ui-component/extended/Transitions';

// constant
const icons = {
  IconCheckbox,
  IconReportAnalytics,
  ApartmentIcon,
  PeopleAltIcon,
  BarChartIcon,
  PersonSearchIcon,
  ContactsIcon,
  Percent,
  Feed,
  FeedbackIcon,
  PlayLesson,
  RequestQuote,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const data = {
  id: 'customers-data',
  title: 'Customers Data',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'query',
      title: "User's Queries",
      type: 'item',
      url: '/query',
      icon: ChatIcon,
      breadcrumbs: true,
    },
    {
      id: 'freemium',
      title: 'Freemium Data',
      type: 'item',
      url: '/freemium',
      icon: icons.BarChartIcon,
      breadcrumbs: true,
    },
    {
      id: 'customers',
      title: 'Customers Data',
      type: 'item',
      url: '/customers',
      icon: icons.PersonSearchIcon,
      breadcrumbs: true,
    },
    {
      id: 'contact-us',
      title: 'Contact Us',
      type: 'item',
      url: '/contact-us',
      icon: icons.ContactsIcon,
      breadcrumbs: true,
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: '/transactions',
      icon: icons.RequestQuote,
      breadcrumbs: true,
    },
    {
      id: 'voucher-data',
      title: 'Voucher Data',
      type: 'item',
      url: '/voucher',
      icon: icons.Percent,
      breadcrumbs: true,
    },
    {
      id: 'newsletter',
      title: 'Newsletter',
      type: 'item',
      url: '/newsletter',
      icon: icons.Feed,
      breadcrumbs: true,
    },
    {
      id: 'feedback',
      title: 'Feedback',
      type: 'item',
      url: '/feedback',
      icon: icons.FeedbackIcon,
      breadcrumbs: true,
    },
    {
      id: 'ebooks',
      title: 'Ebooks',
      type: 'item',
      url: '/ebooks',
      icon: icons.PlayLesson,
      breadcrumbs: true,
    },
  ],
};

export default data;
