import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import { pathToRegexp } from 'path-to-regexp';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project imports
import config from 'config';
import { gridSpacing } from 'store/constant';

// assets
import { IconTallymark1 } from '@tabler/icons';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize' // Added line
};

const iconStyle = {
    marginRight: '0.25rem',
    marginTop: '-0.125rem',
    width: '1rem',
    height: '1rem'
};

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ card, divider, icon, icons, maxItems, navigation, rightAlign, separator, title, titleBottom, ...others }) => {
    const theme = useTheme();
    const location = useLocation();
    const [parent, setParent] = useState();
    const [main, setMain] = useState();
    const [item, setItem] = useState();

    // This function is used to find the route that matches the given pathname from the given items.
    const findMatchingRoute = (pathname, items, parentItems = []) => {
        // Initialize variables to hold the current item and parent item.
        let currentItem = null;
        let parentItem = parentItems;

        // Loop through each item in the items array.
        for (const item of items) {
            // If the current item is a 'group', we need to go deeper into the navigation structure.
            if (item.type === 'group') {
                // Recursive call to the same function with the current item's children and update parentItems array.
                const { item: matchedItem, parent: matchedParent } = findMatchingRoute(pathname, item.children, [...parentItems, item]);
                // If a matching item is found in the children of the current item,
                if (matchedItem) {
                    // update the current item and parent item.
                    currentItem = matchedItem;
                    // The parent item is the matched parent (if any), or the current item itself.
                    parentItem = matchedParent;
                    // Break out of the loop since we've found a match.
                    break;
                }
            }
            // If the current item is an 'item', we need to check if its URL matches the pathname.
            else if (item.type === 'item') {
                // Split the item's URL and the pathname into parts.
                const itemUrlParts = item.url.split('/');
                const pathnameParts = pathname.split('/');
                // Check if the number of parts is the same, and each corresponding part is either equal or is a dynamic part (starts with ':').
                const isMatching =
                    itemUrlParts.length === pathnameParts.length &&
                    itemUrlParts.every((part, index) => part === pathnameParts[index] || part.startsWith(':'));
                // If the current item's URL matches the pathname,
                if (isMatching) {
                    // update the current item and add it to the parentItem array.
                    currentItem = item;
                    parentItem = [...parentItem, item];
                    break;
                }
            }
        }

        // Return the current item and parent item, whether they were found or are still null.
        return { item: currentItem, parent: parentItem };
    };

    // This function is used to extract the dynamic part from the path that matches a certain pattern from the navigation structure.
    const extractDetailFromPath = (path, navigation) => {
        // Define a variable to hold the dynamic part.
        // It will be updated as we loop through the navigation items and find a match.
        let colonItem = null;

        // Loop through each item in the navigation.items array.
        // The optional chaining operator (?.) is used to ensure that we don't get a TypeError if navigation or navigation.items is undefined.
        navigation?.items?.map((menu) => {
            // If the current item is a 'group', we need to go deeper into the navigation structure.
            if (menu.type && menu.type === 'group') {
                // Loop through the children of the current group.
                menu.children.filter((item) => {
                    // Split the item's URL into parts.
                    const pathParts = item.url.split('/');
                    // Find the index of the part that starts with ':' (dynamic part).
                    const colonIndex = pathParts.findIndex((part) => part.startsWith(':'));
                    // If a dynamic part is found,
                    if (colonIndex !== -1) {
                        // Extract the corresponding part from the given path and update colonItem.
                        const dynamicPart = path.split('/')[colonIndex];
                        colonItem = dynamicPart;
                    }
                    // The filter function expects a boolean to decide whether to include the current item in the resulting array.
                    // But since we're not actually using the resulting array, we just return false.
                    return false;
                });
            }
            // The map function expects a return value to form the new array.
            // But since we're not actually using the resulting array, we just return false.
            return false;
        });

        // Return the dynamic part that was found, or null if no matching part was found.
        return colonItem;
    };
    // This function is used to extract the last dynamic part from the path.
    const extractLastDynamicFromPath = (path, navigation) => {
        // Define a variable to hold the dynamic part.
        // It will be updated as we loop through the navigation items and find a match.
        let lastDynamicPart = null;

        // Loop through each item in the navigation.items array.
        navigation?.items?.map((menu) => {
            // If the current item is a 'group', we need to go deeper into the navigation structure.
            if (menu.type && menu.type === 'group') {
                // Loop through the children of the current group.
                menu.children.filter((item) => {
                    // Split the item's URL and the path into parts.
                    const itemUrlParts = item.url.split('/');
                    const pathParts = path.split('/');
                    // Loop through each part of the item's URL.
                    for (let i = 0; i < itemUrlParts.length; i++) {
                        // If the current part is dynamic (starts with ':'),
                        if (itemUrlParts[i].startsWith(':')) {
                            // and there is a corresponding part in the path,
                            if (pathParts[i]) {
                                // update the dynamic part.
                                lastDynamicPart = pathParts[i];
                            }
                        }
                    }
                    return false;
                });
            }
            return false;
        });

        // Return the last dynamic part that was found, or null if no matching part was found.
        return lastDynamicPart;
    };

    const capitalizeFirstLetter = (str) => {
        // Replace %20 with a space.
        str = str.replace(/%20/g, ' ');
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    useEffect(() => {
        // The current path is retrieved from the document's location.
        const currentPath = document.location.pathname;

        // The findMatchingRoute function is used to find the current item and its parent.
        const { item: currentItem, parent: parentItem } = findMatchingRoute(currentPath, navigation?.items);

        // If a current item was found,
        if (currentItem) {
            // The main menu and the item states are set.
            setMain(parentItem);
            setItem(currentItem);

            // The dynamic part of the path is extracted using the extractDetailFromPath function.
            const child = extractLastDynamicFromPath(currentPath, navigation);

            // If there is a dynamic part,
            if (child) {
                // Split the child on the '-' character and take the first part.
                const childFirstPart = child.split('-')[0];
                // The item's title is updated with the first part of the dynamic part and the item state is set.
                setItem({ ...currentItem, title: `${currentItem.title} - ${capitalizeFirstLetter(childFirstPart)}` });
                // The default parent item of the current group is found and the parent state is set.
                const defaultParentItem = parentItem?.children?.find((item) => item.id === 'default');
                if (defaultParentItem) {
                    setParent(defaultParentItem);
                }
            } else {
                // If there is no dynamic part, the parent state is set without modifying the item.
                setParent(parentItem);
            }
        }
        // The navigation structure and the location are dependencies of this effect.
        // This means that the effect will run again every time one of these values changes.
    }, [navigation, location]);

    // item separator
    const SeparatorIcon = separator;
    const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="1rem" /> : <IconTallymark1 stroke={1.5} size="1rem" />;

    let mainContent;
    let itemContent;
    let breadcrumbContent = <Typography />;
    let itemTitle = '';
    let CollapseIcon;
    let ItemIcon;

    // collapse item
    if (main && main.type === 'collapse') {
        CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
        mainContent = (
            <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
                {icons && <CollapseIcon style={{ ...iconStyle, color: theme.palette.secondary.main }} />}
                {main.title}
            </Typography>
        );
    }

    // items
    if (item && item.type === 'item') {
        itemTitle = item.title;
        ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
        itemContent = (
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'grey.500'
                }}
            >
                {icons && <ItemIcon style={{ ...iconStyle, color: theme.palette.secondary.main }} />}
                {capitalizeFirstLetter(itemTitle)}
            </Typography>
        );

        if (parent && parent.type === 'item') {
            ItemIcon = parent.icon ? parent.icon : AccountTreeTwoToneIcon;
            mainContent = (
                <Typography component={Link} to={config.basename + parent.url} variant="subtitle1" sx={linkSX}>
                    {icons && <ItemIcon style={{ ...iconStyle, color: theme.palette.secondary.main }} />}
                    {parent.title}
                </Typography>
            );
        }

        const createBreadcrumbItems = (pathSegments) => {
            const breadcrumbItems = [
                <Typography component={Link} to="/" color="inherit" variant="subtitle1" sx={linkSX}>
                    {icons && <HomeTwoToneIcon style={{ ...iconStyle, color: theme.palette.secondary.main }} />}
                    {icon && <HomeIcon sx={{ ...iconStyle, mr: 0, color: theme.palette.secondary.main }} />}
                    {!icon && 'Home'}
                </Typography>
            ];

            let path = '';
            for (let i = 0; i < pathSegments.length; i++) {
                const segment = pathSegments[i];
                path += `/${segment}`;

                // Split the segment at the '-' character, and keep only the first part for the breadcrumb label.
                const breadcrumbLabel = segment.split('-')[0].replace(/%20/g, ' ');

                if (i === pathSegments.length - 1) {
                    // Check if it's the last segment
                    breadcrumbItems.push(
                        <Typography variant="subtitle1" sx={{ color: 'grey.500' }}>
                            {capitalizeFirstLetter(breadcrumbLabel)}
                        </Typography>
                    );
                } else {
                    breadcrumbItems.push(
                        <Typography component={Link} to={path} color="inherit" variant="subtitle1" sx={linkSX}>
                            {breadcrumbLabel}
                        </Typography>
                    );
                }
            }

            return breadcrumbItems;
        };

        // main
        if (item.breadcrumbs !== false) {
            const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');
            const breadcrumbItems = createBreadcrumbItems(pathSegments);

            breadcrumbContent = (
                <Card
                    sx={{
                        marginBottom: card === false ? 0 : theme.spacing(gridSpacing),
                        border: card === false ? 'none' : '1px solid',
                        borderColor: theme.palette.primary[200] + 75,
                        background: card === false ? 'transparent' : theme.palette.background.default
                    }}
                    {...others}
                >
                    <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                        <Grid
                            container
                            direction={rightAlign ? 'row' : 'column'}
                            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                            alignItems={rightAlign ? 'center' : 'flex-start'}
                            spacing={1}
                        >
                            {title && !titleBottom && (
                                <Grid item>
                                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                        {capitalizeFirstLetter(itemTitle)}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <MuiBreadcrumbs
                                    sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                                    aria-label="breadcrumb"
                                    maxItems={maxItems || 8}
                                    separator={separatorIcon}
                                >
                                    {breadcrumbItems}
                                </MuiBreadcrumbs>
                            </Grid>
                            {title && titleBottom && (
                                <Grid item>
                                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                        {capitalizeFirstLetter(itemTitle)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: gridSpacing }} />}
                </Card>
            );
        }
    }

    return breadcrumbContent;
};

Breadcrumbs.propTypes = {
    card: PropTypes.bool,
    divider: PropTypes.bool,
    icon: PropTypes.bool,
    icons: PropTypes.bool,
    maxItems: PropTypes.number,
    navigation: PropTypes.object,
    rightAlign: PropTypes.bool,
    separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    title: PropTypes.bool,
    titleBottom: PropTypes.bool
};

export default Breadcrumbs;
