// assets
import { IconCheckbox, IconReportAnalytics } from "@tabler/icons";

//MUI Icon
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";


// constant
const icons = {
  IconCheckbox,
  IconReportAnalytics,
  ApartmentIcon,
  PeopleAltIcon,

};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'userSpace',
  title: 'Users Detail',
  caption: 'Access details regarding individual users or SMEs.',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Individual Users',
      type: 'item',
      url: '/users',
      icon: icons.PeopleAltIcon,
      breadcrumbs: true,
    },
    {
      id: 'reports',
      title: 'Users Reports',
      type: 'item',
      url: '/reports',
      icon: icons.IconReportAnalytics,
      breadcrumbs: true,
    },

    // {
    //   id: "company",
    //   title: "Companies",
    //   type: "item",
    //   url: "/companies",
    //   icon: icons.ApartmentIcon,
    //   breadcrumbs: true,
    // },
    {
      id: 'company-details',
      title: 'Company Details',
      type: 'item',
      url: '/companies/:name/:id',
      breadcrumbs: true,
      hidden: true,
      parent: 'company',
    },
  ],
};

export default pages;
