// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    borderRadius12: config.borderRadius12,
    opened: true,
    Authed: null,
    status: {
        all_package_sold: '',
        all_users: '',
        companies: '',
        single_users: '',
        sme_coach_users: ''
    }
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_BORDER_RADIUS12:
            return {
                ...state,
                borderRadius12: action.borderRadius12
            };
        case actionTypes.SET_AUTHED:
            console.log(action);
            return {
                ...state,
                Authed: action.Authed
            };
        case actionTypes.SET_STATUS:
            return {
                ...state,
                status: action.status
            };
        default:
            return state;
    }
};

export default customizationReducer;
