// assets
import { IconLayout } from '@tabler/icons';

import AppsIcon from '@mui/icons-material/Apps';
import DetailIcon from '@mui/icons-material/Details'; // Add an import for the detail page icon
import BarChartIcon from '@mui/icons-material/BarChart';
// constant
const icons = { IconLayout, AppsIcon, DetailIcon, BarChartIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'product',
  title: 'Packages',
  type: 'group',
  caption:
    'This section enables you to take charge of your products, add new ones or edit the existing ones.',
  children: [
    {
      id: 'default',
      title: 'Packages',
      type: 'item',
      url: '/products',
      icon: icons.AppsIcon,
      breadcrumbs: true,
    },
    {
      id: 'summary-report',
      title: 'Summary Reports',
      type: 'item',
      url: '/summary-report',
      icon: icons.BarChartIcon,
      breadcrumbs: true,
    },
    {
      id: 'package-details',
      title: 'Package Details',
      type: 'item',
      url: '/products/:detail',
      breadcrumbs: true,
      hidden: true,
      parent: 'default',
    },
    {
      id: 'theme-details',
      title: 'Theme Details',
      type: 'item',
      url: '/products/:detail/:theme',
      breadcrumbs: true,
      hidden: true,
      parent: 'package-details',
    },
  ],
};

export default dashboard;
