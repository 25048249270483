// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import useGetRole from "hooks/useGetRole";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { role } = useGetRole();

  const nonSuperAdminMenuItems = menuItem.items
    .map((item) => ({
      ...item,
      children: item.children.filter((n) => n.id !== 'customers'),
    }))
    .filter((item) => item.id !== 'product');
  
  const roleBasedMenuItems =
    role === "sUpErADmin" ? menuItem.items : nonSuperAdminMenuItems;

  const navItems = roleBasedMenuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
