import config from './global';

export default {
  login: (body, isLogin) => {
    return config.axiosHandler(isLogin).post('/ld360ad/login', body);
  },
  productList: () => {
    return config.axiosHandler().get('/products');
  },
  insertProduct: (body) => {
    return config.axiosHandler().post('/products', body);
  },
  updateProduct: (body, id) => {
    return config.axiosHandler().put(`/products/${id}`, body);
  },
  getDetail: () => {
    return config.axiosHandler().get('/adget');
  },
  removeProduct: (id) => {
    return config.axiosHandler().delete(`/products/${id}`);
  },
  listReportNarrative: (id) => {
    return config.axiosHandler().get(`/products/${id}/narrativereport`);
  },
  insertReportNarrative: (body, id) => {
    return config.axiosHandler().post(`/products/${id}/narrativereport`, body);
  },
  updateReportNarrative: (body, id) => {
    return config
      .axiosHandler()
      .put(`/products/${id}/narrativereport/${body.id}`, body);
  },
  removeReportNarrative: (body, id) => {
    return config
      .axiosHandler()
      .delete(`/products/${body.productId}/narrativereport/${body.id}`);
  },
  listTheme: (id) => {
    return config.axiosHandler().get(`/products/${id}/themes`);
  },
  insertTheme: (body, id) => {
    return config.axiosHandler().post(`/products/${id}/themes`, body);
  },
  updateTheme: (body, id) => {
    return config
      .axiosHandler()
      .put(`/products/${body.productId}/themes/${body.id}`, body);
  },
  removeTheme: (body, id) => {
    return config
      .axiosHandler()
      .delete(`/products/${body.productId}/themes/${body.id}`, body);
  },
  listInsights: (id) => {
    return config.axiosHandler().get(`themes/${id}/insight`);
  },
  insertInsight: (body, id) => {
    return config.axiosHandler().post(`themes/${id}/insight`, body);
  },
  updatetInsight: (body, id) => {
    return config.axiosHandler().put(`themes/${id}/insight/${body.id}`, body);
  },
  removeInsight: (body, id) => {
    return config.axiosHandler().delete(`themes/${id}/insight/${body.id}`);
  },
  listQuestion: (id) => {
    return config.axiosHandler().get(`themes/${id}/questions`);
  },
  insertQuestion: (body, id) => {
    return config.axiosHandler().post(`themes/${id}/questions`, body);
  },
  updateQuestion: (body, id) => {
    return config.axiosHandler().put(`themes/${id}/questions/${body.id}`, body);
  },
  removeQuestion: (id, bodyId) => {
    return config.axiosHandler().delete(`themes/${id}/questions/${bodyId}`);
  },
  insertQuestionNarrative: (body) => {
    return config
      .axiosHandler()
      .post(`questions/${body.question_id}/question_narratives`, body.data);
  },
  updateQuestionNarrative: (body) => {
    return config
      .axiosHandler()
      .put(`question_narratives/${body.question_id}`, body.data);
  },
  removeQuestionNarrative: (id) => {
    return config.axiosHandler().delete(`question_narratives/${id}`);
  },
  getSingleUsers: () => {
    return config.axiosHandler().get('singleusers');
  },
  getCompanies: () => {
    return config.axiosHandler().get('companies');
  },
  getAttempts: (id) => {
    return config.axiosHandler().get(`get_feedback_attempts/${id}`);
  },
  getFeedback: (id) => {
    return config.axiosHandler().get(`get_feedback/${id}`);
  },
  getMemberCompany: (id) => {
    return config.axiosHandler().get(`companies/members/${id}`);
  },
  // leadingStatus: () => {
  //   return config.axiosHandler().get(`leading_status`);
  // },
  login: (body, isLogin) => {
    return config.axiosHandler(isLogin).post('/ld360ad/login', body);
  },
  productList: () => {
    return config.axiosHandler().get('/products');
  },
  insertProduct: (body) => {
    return config.axiosHandler().post('/products', body);
  },
  updateProduct: (body, id) => {
    return config.axiosHandler().put(`/products/${id}`, body);
  },
  getDetail: () => {
    return config.axiosHandler().get('/adget');
  },
  removeProduct: (id) => {
    return config.axiosHandler().delete(`/products/${id}`);
  },
  listReportNarrative: (id) => {
    return config.axiosHandler().get(`/products/${id}/narrativereport`);
  },
  insertReportNarrative: (body, id) => {
    return config.axiosHandler().post(`/products/${id}/narrativereport`, body);
  },
  updateReportNarrative: (body, id) => {
    return config
      .axiosHandler()
      .put(`/products/${id}/narrativereport/${body.id}`, body);
  },
  removeReportNarrative: (body, id) => {
    return config
      .axiosHandler()
      .delete(`/products/${body.productId}/narrativereport/${body.id}`);
  },
  listTheme: (id) => {
    return config.axiosHandler().get(`/products/${id}/themes`);
  },
  insertTheme: (body, id) => {
    return config.axiosHandler().post(`/products/${id}/themes`, body);
  },
  updateTheme: (body, id) => {
    return config
      .axiosHandler()
      .put(`/products/${body.productId}/themes/${body.id}`, body);
  },
  removeTheme: (body, id) => {
    return config
      .axiosHandler()
      .delete(`/products/${body.productId}/themes/${body.id}`, body);
  },
  listInsights: (id) => {
    return config.axiosHandler().get(`themes/${id}/insight`);
  },
  insertInsight: (body, id) => {
    return config.axiosHandler().post(`themes/${id}/insight`, body);
  },
  updatetInsight: (body, id) => {
    return config.axiosHandler().put(`themes/${id}/insight/${body.id}`, body);
  },
  removeInsight: (body, id) => {
    return config.axiosHandler().delete(`themes/${id}/insight/${body.id}`);
  },
  listQuestion: (id) => {
    return config.axiosHandler().get(`themes/${id}/questions`);
  },
  insertQuestion: (body, id) => {
    return config.axiosHandler().post(`themes/${id}/questions`, body);
  },
  updateQuestion: (body, id) => {
    return config.axiosHandler().put(`themes/${id}/questions/${body.id}`, body);
  },
  removeQuestion: (id, bodyId) => {
    return config.axiosHandler().delete(`themes/${id}/questions/${bodyId}`);
  },
  insertQuestionNarrative: (body) => {
    return config
      .axiosHandler()
      .post(`questions/${body.question_id}/question_narratives`, body.data);
  },
  updateQuestionNarrative: (body) => {
    return config
      .axiosHandler()
      .put(`question_narratives/${body.question_id}`, body.data);
  },
  removeQuestionNarrative: (id) => {
    return config.axiosHandler().delete(`question_narratives/${id}`);
  },
  getSingleUsers: () => {
    return config.axiosHandler().get('singleusers');
  },
  getDataQueries: () => {
    return config.axiosHandler().get('queries');
  },
  getFreemiumData: () => {
    return config.axiosHandler().get('freemium_data');
  },
  getCustomersData: (id) => {
    return config.axiosHandler().get(`customers/${id}`);
  },
  getCompanies: () => {
    return config.axiosHandler().get('companies');
  },
  getAttempts: (id) => {
    return config.axiosHandler().get(`get_feedback_attempts/${id}`);
  },
  getFeedback: (id) => {
    return config.axiosHandler().get(`get_feedback/${id}`);
  },
  getMemberCompany: (id) => {
    return config.axiosHandler().get(`companies/members/${id}`);
  },

  //Interception APIS
  Interseotion: (id, method = 'get', body, incID) => {
    return config
      .axiosHandler()
      [method](`/themes/${id}/interception${incID ? `/${incID}` : ''}`, body);
  },
  //Interception APIS
  Interseotion: (id, method = 'get', body, incID) => {
    return config
      .axiosHandler()
      [method](`/themes/${id}/interception${incID ? `/${incID}` : ''}`, body);
  },

  test: () => {
    return config.axiosHandler().get('/customers_data/Insights');
  },
  //Contact us
  getcontactUs: () => {
    return config.axiosHandler().get('/contact_us');
  },
  //Voucher Data
  getVoucherData: () => {
    return config.axiosHandler().get('/vouchers_data');
  },
  //newsletter Data
  getNewsletter: () => {
    return config.axiosHandler().get('/newsletter');
  },
  //feedback Data
  getFeedbackData: () => {
    return config.axiosHandler().get('/user_feedback');
  },
  test: () => {
    return config.axiosHandler().get('/customers_data/Insights');
  },
  getRole: () => {
    return config.axiosHandler().get('/adget');
  },
  getFeedback: () => {
    return config.axiosHandler().get('/admin_feedback');
  },
  sendFeedback: (id, feedback) => {
    return config.axiosHandler().put(`/admin_feedback/${id}`, feedback);
  },
  addPartner: (partner) => {
    return config.axiosHandler().post(`/partners`, partner);
  },
  updatePartner: (partner) => {
    return config.axiosHandler().put(`/partners`, partner);
  },
  deletePartner: (id) => {
    return config.axiosHandler().delete(`/partners/${id}`);
  },
  getPartners: () => {
    return config.axiosHandler().get(`/partners`);
  },
  addVoucher: (voucher) => {
    console.log(voucher);
    return config.axiosHandler().post(`/voucher/create`, voucher);
  },
  updateVoucher: (voucher) => {
    return config.axiosHandler().put(`/voucher`, voucher);
  },
  deleteVoucher: (id) => {
    return config.axiosHandler().delete(`/voucher/${id}`);
  },
  getVoucher: () => {
    return config.axiosHandler().get(`/voucher`);
  },
  addTask: (task, setUploadProgress, uploadCancelled) => {
    return config.axiosHandler().post(`/tasks`, task, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(progress);
      },
      cancelToken: uploadCancelled.token,
    });
  },
  updateTask: (task, setUploadProgress, uploadCancelled) => {
    return config.axiosHandler().put(`/tasks`, task, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(progress);
      },
      cancelToken: uploadCancelled.token,
    });
  },
  deleteTask: (id) => {
    return config.axiosHandler().delete(`/tasks/${id}`);
  },
  getTasks: () => {
    return config.axiosHandler().get(`/tasks`);
  },
  getEbooks: () => {
    return config.axiosHandler().get(`/ebook_data`);
  },
  getUsersReport: (id) => {
    return config.axiosHandler().get(`/users/attempts/${id}`);
  },

  //---------------------PDF API--------------------//

  getPdfReports: (body) => {
    return config.axiosHandler().post(`/users/reports`, body);
  },
  getinsights: (body) => {
    return config.axiosHandler().post('users/insights', body);
  },
  getInterpreter: (body) => {
    return config.axiosHandler().post('users/interpreter', body);
  },
  // summary reports
  getSummaryReport: (id) => {
    return config.axiosHandler().get(`/summary_report/${id}`);
  },
  postSummaryReport: (body) => {
    return config.axiosHandler().post(`/summary_report`, body);
  },
  putSummaryReport: (body) => {
    return config.axiosHandler().put(`/summary_report`, body);
  },
  // Question api
  getTopQuestions: (id) => {
    return config.axiosHandler().get(`/questions/topfive/${id}`);
  },
  getQuestionSummary: (id) => {
    return config.axiosHandler().get(`/questions/summary/${id}`);
  },
  postQuestionSummary: (body) => {
    return config.axiosHandler().post(`/questions/summary`, body);
  },
  putQuestionSummary: (body) => {
    return config.axiosHandler().put(`/questions/summary`, body);
  },
  //==================== Summary Report =========================
  getSummary: (body) => {
    return config.axiosHandler().post('/summary/user', body);
  },
  //==================== Transaction Data =========================
  getTransactions: () => {
    return config.axiosHandler().get('/transactions/db');
  },
};
