// assets
import { IconLayout } from "@tabler/icons";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DiscountIcon from "@mui/icons-material/Discount";
// constant
const icons = { IconLayout, ManageAccountsIcon, DiscountIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const voucherAndPartnership = {
  id: "voucher&partneship",
  title: "Voucher and Partnership",
  type: "group",
  caption: "",
  children: [
    {
      id: "partnership",
      title: "Partners",
      type: "item",
      url: "/partners",
      icon: icons.ManageAccountsIcon,
      breadcrumbs: true,
    },
    {
      id: "vocher",
      title: "Voucher Code",
      type: "item",
      url: "/voucher-code",
      icon: icons.DiscountIcon,
      breadcrumbs: true,
    },
  ],
};

export default voucherAndPartnership;
