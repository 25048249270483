const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/products',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 20,
    borderRadius12: 20
};

export default config;
